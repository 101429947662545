import "@fontsource/margarine"; // Ensure you have the font installed
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import WorkWithUs from "../Components/WorkWithUs";
const Products = ({ data }) => {
  useEffect(() => {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          sx={{ padding: "20px", marginTop: "80px" }}
        >
          <Grid item>
            <img
              src={data.logo} // Use logo from JSON
              alt="Refease Logo"
              style={{
                maxWidth: "200px",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" sx={{ marginTop: "20px" }}>
          <Grid item>
            <Typography
              variant="h1"
              sx={{
                fontSize: "128px",
                fontWeight: "bold",
                fontFamily: "'Margarine', sans-serif",
              }}
            >
              {data.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" sx={{ marginTop: "20px" }}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" align="left" sx={{ color: "#666" }}>
              {data.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          sx={{ marginTop: "50px" }}
        >
          <Grid item xs={12} md={6}>
            <img
              src={data.clinicImage} // Use clinic image from JSON
              alt="Clinic Image"
              style={{
                maxWidth: "100%",
                maxHeight: "400px",
                height: "auto",
                borderRadius: "20px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {data?.features?.map((feature, index) => (
              <Typography
                key={index}
                variant="h5"
                align="left"
                sx={{ color: "#666", marginTop: "30px", textAlign: "justify" }}
              >
                {feature}
              </Typography>
            ))}
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ marginTop: "50px" }}>
          <iframe
            width="560"
            height="315"
            src={data.videoSrc} // Use video source from JSON
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ borderRadius: "10px" }}
          ></iframe>
        </Grid>
      </Container>
      <WorkWithUs />
    </>
  );
};
export default Products;
