import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet-async";
import gif from "./innoit.gif";

function GetInTouch() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const textFields = [
    {
      id: "companyName",
      label: "Company Name",
      testid: "Companyname-textfield",
    },
    { id: "yourName", label: "Your Name" },
    { id: "emailAddress", label: "Email address" },
    { id: "phoneNumber", label: "Phone Number" },
  ];

  const [formData, setFormData] = useState({
    companyName: "",
    yourName: "",
    emailAddress: "",
    phoneNumber: "",
  });

  const [description, setDescription] = useState("");

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const validateForm = () => {
    const { companyName, yourName, emailAddress, phoneNumber } = formData;
    if (!companyName || !yourName || !emailAddress || !phoneNumber) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill in all the required fields.");
      return;
    }
    setLoading(true);
    const templateParams = {
      company_name: formData.companyName,
      your_name: formData.yourName,
      email_address: formData.emailAddress,
      phone_number: formData.phoneNumber,
      description: description,
    };

    emailjs
      .send(
        "service_6dixfmb",
        "template_wex3e0g",
        templateParams,
        "8QcIg65YqsTK7GqnK"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormData({
            companyName: "",
            yourName: "",
            emailAddress: "",
            phoneNumber: "",
          });
          setLoading(false);
          alert("Email sent successfully!");
        },
        (err) => {
          console.log("FAILED...", err);
          alert("Failed to connect Us!!. Please try again later.");
        }
      );
  };

  return (
    <Grid container style={{ marginTop: "10vh" }}>
      <Helmet>
        <title>Contact Us - Get in Touch with ByteCrafts</title>
        <meta
          name="description"
          content="Have questions or need assistance? Contact ByteCrafts today! Our dedicated team is here to help you with any inquiries regarding our innovative digital solutions."
        />
      </Helmet>

      <Dialog
        open={loading}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent>
          <img
            src={gif}
            alt="Loading..."
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
      <Grid
        container
        style={{
          marginTop: "10vh",
          display: "flex",
          flexDirection: "row", // Ensure side-by-side alignment
          flexWrap: "nowrap",
        }}
      >
        {/* Form Section */}
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          style={{
            fontSize: "40px",
            textAlign: "center",
            backgroundColor: "#fef4f3",
          }}
        >
          <Box
            sx={{
              width: "100%",
              padding: "20px",
              margin: "0 auto",
              paddingBottom: "40px",
            }}
          >
            <b>Get in Touch!!!</b>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "90%" },
              }}
              noValidate
              autoComplete="off"
            >
              {textFields.map((field) => (
                <TextField
                  key={field.id}
                  id={field.id}
                  data-testid={field.testid}
                  label={field.label}
                  variant="outlined"
                  value={formData[field.id]}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "#D9D9D933",
                    },
                  }}
                  sx={{
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#D9D9D933",
                      },
                    },
                  }}
                  fullWidth
                  margin="normal"
                />
              ))}
            </Box>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "90%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="description"
                label="Tell us more about your project"
                variant="outlined"
                onChange={(e) => setDescription(e.target.value)}
                InputProps={{
                  sx: {
                    height: "200px",
                    backgroundColor: "#D9D9D933",
                  },
                }}
                sx={{
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#D9D9D933",
                    },
                  },
                }}
              />
              <Button
                onClick={(e) => handleSubmit(e)}
                variant="contained"
                sx={{
                  backgroundColor: "#f9e3e3",
                  color: "#000",
                  width: "150px",
                  height: "45px",
                  borderRadius: "30px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#f4caca",
                  },
                }}
              >
                SUBMIT
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* Image Section */}
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fef4f3", // Ensure consistent background
          }}
        >
          <Box
            component="img"
            src="/contcatus/Getintouch.jpg"
            alt="Get in Touch"
            sx={{
              width: "80%", // Scale image appropriately
              height: "auto",
              maxWidth: "100%", // Ensure it doesn't overflow
              borderRadius: "40px", // Consistent border radius
              zIndex: 1, // Ensure it stays above the background
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "4vh",
          marginBottom: "4vh",
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.8525095760915!2d75.91676197480275!3d14.435662081094137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba25801488f567%3A0x1dee8cf47f9a23f1!2sByteCrafts!5e0!3m2!1sen!2sus!4v1728835241621!5m2!1sen!2sus"
          width="800"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Grid>
    </Grid>
  );
}

export default GetInTouch;
