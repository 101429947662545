import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography
} from '@mui/material';
import MenuBookSharpIcon from '@mui/icons-material/MenuBookSharp';
import { Link } from 'react-router-dom';

const drawerButtonStyles = {
  color: '#1e558d',
  fontSize: '18px',
  '&:hover': {
    color: '#69b6b1',
    backgroundColor: 'transparent'
  }
};

const DrawerComponent = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  

  return (
    <>
      <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/" sx={drawerButtonStyles}>
                  Home
                </Button>
              </Typography>
            </ListItemText>
          </ListItem> 
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/contact" sx={drawerButtonStyles}>
                  Contact Us
                </Button>
              </Typography>
            </ListItemText>
          </ListItem> 
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuBookSharpIcon style={{ fontSize: '2em', color: 'white' }} />
      </IconButton>
    </>
  );
};

export default DrawerComponent;
