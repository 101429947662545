import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom'; // Import createRoot
import { StyledEngineProvider } from '@mui/material';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StyledEngineProvider>
);

reportWebVitals();
