import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Section1({ hideBtn = false }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#F5F1F1",
          marginTop: { xs: "8vh", sm: "0vh", md: "10vh" },
        }}
        style={{ paddingLeft: isMobile ? "6vh" : "18vh" }}
      >
        <Grid item xs={12} sx={{ marginTop: "18px" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "30px", sm: "50px", md: "50px", lg: "50px" },
              whiteSpace: "nowrap",
            }}
          >
            We tailor Solutions to
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "30px", sm: "50px", md: "50px", lg: "50px" },
              whiteSpace: "nowrap",
            }}
          >
            meet your <span style={{ color: "#FB8686" }}>Every</span>{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "20px", sm: "30px", md: "40px", lg: "50px" },
              whiteSpace: "nowrap",
              display: "inline",
            }}
          >
            Business Need.
          </Typography>
        </Grid>
        {hideBtn ? null : (
          <>
            <Grid
              item
              xs={12}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Button
                onClick={() => navigate("/contact")}
                sx={{
                  borderRadius: "40px",
                  paddingLeft: {
                    xs: "8vh",
                    sm: "15vh",
                    md: "25vh",
                  },
                  paddingRight: {
                    xs: "8vh",
                    sm: "15vh",
                    md: "25vh",
                  },
                  marginLeft: "auto",
                  backgroundColor: "#FBC3C3",
                  color: "#000000",
                  fontWeight: "bold",
                  transition:
                    "transform 0.7s, background-color 0.1s, color 0.1s",
                  ":hover": {
                    backgroundColor: "#FBC3C3",
                    color: "#000000",
                    transform: "scale(1.03)",
                  },
                }}
              >
                Book a Demo
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <Typography style={{ color: "#FB8686", fontSize: "24px" }}>
                <Button
                  component={Link}
                  to="/aboutus"
                  sx={{
                    color: "#FB8686",
                    fontSize: "24px",
                    transition:
                      "transform 0.7s, background-color 0.1s, color 0.1s",
                    ":hover": {
                      transform: "scale(1.03)",
                    },
                  }}
                >
                  Know more about us +
                </Button>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Section1;
