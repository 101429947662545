import React from "react";
import namnews from './namnewsData.json'; // Import the JSON data
import Products from "./Products";

const Namnews = () => {
  return (
    <Products data={namnews} />
  );
};

export default Namnews;
