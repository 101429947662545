// ScrollNavbar.js
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  CssBaseline,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import DrawerComponent from "./DrawerComponent";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Import the dropdown icon

const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null); // State for the dropdown menu

  useEffect(() => {
    const handleScroll = () => {};

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle dropdown menu open and close
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemStyles = {
    justifyContent: "space-around",
    color: "#FBC3C3",
    backgroundColor: "#787878",
    "&:hover": {
      backgroundColor: "#5C5B5B",
      color: "#FFFFFF",
    },
  };

  const products = [
    { name: "RefEase", img: "/assets/products/refeaselogo.jpeg", testid: "RefEase-component"},
    { name: "StockNest", img: "/assets/products/stocknests.jpeg", testid:"Stocknest-component"},
    { name: "NamNews", img: "/assets/products/namnewsLogo.png" , testid: "Namnews-component"},
  ];

  return (
    <>
      <AppBar
        position="fixed"
        elevation={4}
        style={{
          background: "black",
          width: "100%",
          transition: isMobile
            ? "none"
            : "width 0.5s ease-in-out, margin 0.5s ease-in-out, top 0.5s ease-in-out",
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
        }}
      >
        <CssBaseline />
        <Toolbar
          sx={{
            paddingRight: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Left side: Logo and Name */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Link
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={"/assets/logo.png"}
                alt="Logo"
                style={{ height: "60px" }}
              />
              <Typography
                variant="h6"
                style={{
                  color: "#e0dccc",
                  fontFamily: "protest-revolution-font",
                  marginLeft: "10px",
                }}
              >
                ByteCrafts
              </Typography>
            </Link>
          </div>

          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div
              style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
            >
              <div style={{ position: "relative" }}>
                <Button
                  color="inherit"
                  style={{
                    borderTopLeftRadius: "40px",
                    borderBottomLeftRadius: "40px",
                    paddingLeft: "40px",
                    paddingRight: "10px", // Adjusted for icon spacing
                    backgroundColor: "#5C5B5B",
                    color: "#FBC3C3",
                    display: "flex",
                    alignItems: "center",
                  }}
                  data-testid="product-button"
                  onClick={handleClick} // Open dropdown on click
                >
                  Products
                  <ArrowDropDownIcon style={{ marginLeft: "5px" }} />{" "}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    style: {
                      backgroundColor: "#787878",
                    },
                  }}
                  PaperProps={{
                    style: {
                      width: anchorEl ? (anchorEl.clientWidth) : undefined,
                      borderRadius: "8px",
                    },
                  }}
                >
                  {products.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={handleClose}
                      data-testid = {item.testid}
                      component={Link}
                      to={`/products/${item.name.toLowerCase()}`}
                      style={{
                        ...menuItemStyles,
                        backgroundColor: "inherit",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#4a4a4a")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "inherit")
                      }
                    >
                      <img src={item.img} style={{marginRight: '10px', borderRadius: '50px',height: 30, width: 30}} />
                      {item.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>

              <Button
                color="inherit"
                style={{
                  borderTopRightRadius: "40px",
                  borderBottomRightRadius: "40px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  variant: "contained",
                  backgroundColor: "#5C5B5B",
                  color: "#FBC3C3",
                 
                }}
                data-testid="services-button"
                component={Link}
                to="/services"
                
              >
                Services
              </Button>
            </div>
          )}

          {!isMobile && (
            <Button
              data-testid="Letstalk-button"
              component={Link}
              to="/contact"
              sx={{
                borderRadius: "40px",
                paddingLeft: "40px",
                paddingRight: "40px",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(251, 195, 195, 0.95) 0%, rgba(205, 159, 159, 0.95) 45%, rgba(185, 143, 143, 0.95) 65%, rgba(149, 116, 116, 0.95) 100%)",
                color: "#F5F5F5",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, rgba(251, 195, 195, 1) 0%, rgba(205, 159, 159, 1) 45%, rgba(185, 143, 143, 1) 65%, rgba(149, 116, 116, 1) 100%)", // Solid gradient on hover
                },
              }}
            >
              Let's Talk
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
