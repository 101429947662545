import React from "react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import refease from './refeaseData.json'; // Import the JSON data
import Products from "./Products";

const Refease = () => {
  return (
    <Products data={refease} />
  );
};

export default Refease;
