import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { ImageGallery } from "react-image-grid-gallery";
import Section1 from "../HomePage/New/Section2";
import WorkWithUs from "../Components/WorkWithUs";

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width:600px)");

  const cardDetails = [
    {
      title: "Website Building",
      scratch: (
        <svg
          width="252"
          height="80"
          viewBox="0 0 252 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        >
          <path
            d="M7.17432 60.3688C-3.42874 65.3169 28.001 48.5495 31.6341 45.9072C44.7169 36.3925 57.5558 26.5499 70.734 17.1626C76.4402 13.0979 90.2753 0.0754131 89.1235 6.98593C86.517 22.6244 46.5711 46.4389 35.562 56.2624C-0.304573 88.2665 42.0994 58.8339 59.129 50.9063C71.4144 45.1872 104.655 25.9986 97.5146 37.5159C91.3249 47.4995 77.0052 51.2276 70.7339 61.2616C58.8436 80.286 113.732 48.3604 134.829 40.7296C148.532 35.7733 105.737 67.0188 99.3001 71.6168C83.1155 83.1771 125.386 58.5593 126.081 58.2264C132.47 55.1637 198.458 23.9703 185.355 38.9442C179.415 45.7338 139.086 75.6206 166.43 60.7259C175.11 55.9978 224.284 26.7722 218.564 33.9452C213.097 40.8007 166.994 77.6467 193.211 61.44C212.466 49.5369 238.844 35.9565 208.208 63.2255C188.968 80.3518 238.898 48.2722 246.058 45.3716C249.175 44.1091 228.043 63.841 222.491 70.0099"
            stroke="#FB8686"
            strokeWidth="10"
            strokeLinecap="round"
          />
        </svg>
      ),
      desc: "We build unique, tailor-made websites that perfectly represent your brand and achieve your business goals.",
      url: "/services/webs.webp",
    },
    {
      title: "SEO Optimization",
      scratch: (
        <svg
          width="130"
          height="72"
          viewBox="0 0 130 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        >
          <path
            d="M17.7407 32.8915C13.5265 37.1054 -5.27953 55.9872 12.8128 47.3232C29.4709 39.3461 46.1909 29.9729 61.0358 18.9877C63.4333 17.2136 88.6267 -4.53586 72.4757 9.30788C57.4376 22.1978 8.96722 47.2479 28.6526 49.4351C45.855 51.3465 77.7416 22.9844 92.3633 14.5878C128.625 -6.23567 42.121 62.5109 62.6199 50.6672C82.6449 39.0972 161.339 -4.51368 106.443 39.4034C103.633 41.6511 75.9089 63.8489 82.8596 59.291C99.4412 48.4178 128.328 28.3186 149.035 29.3715C155.004 29.675 77.1035 99.2733 148.33 46.2673C182.067 21.1608 111.089 86.1435 163.466 48.7312"
            stroke="#FB8686"
            strokeWidth="10"
            strokeLinecap="round"
          />
        </svg>
      ),
      desc: "We build unique, tailor-made websites that perfectly represent your brand and achieve your business goals.",
      url: "/services/seo.webp",
    },
    {
      title: "E-Commerce Solutions",
      scratch: (
        <svg
          width="381"
          height="66"
          viewBox="0 0 381 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        >
          <path
            d="M16.5588 28.3051C16.1182 32.27 3.37196 36.9394 5.99886 33.9371C9.09566 30.3977 18.8051 25.7112 22.8946 22.6733C32.5172 15.525 44.1888 11.1823 54.926 6.12955C55.2257 5.98849 30.9836 24.0158 24.8305 29.7132C-9.11502 61.1442 65.9463 5.76557 56.862 15.9854C54.3977 18.7578 28.7086 45.1963 41.3744 35.3451C58.7017 21.8683 117.753 -5.02774 102.445 10.7054C91.355 22.1038 76.0462 30.2685 63.7259 40.273C63.6327 40.3487 32.8744 66.1562 49.2941 55.4087C58.4039 49.446 144.239 -3.39081 108.429 29.0091C102.399 34.465 86.669 42.9622 83.0855 50.1288C77.1929 61.9133 106.349 37.7509 117.933 31.4731C127.108 26.5008 149.971 9.83223 161.58 12.8174C162.296 13.0014 135.787 43.4178 133.421 46.2568C124.434 57.041 127.346 54.7549 135.709 47.6648C151.833 33.9945 170.349 22.8502 190.268 15.6334C222.985 3.77929 154.898 53.41 143.981 58.4006C120.466 69.1499 187.841 30.0916 212.267 21.6173C232.021 14.764 159.606 65.4786 164.748 60.3366C170.753 54.3321 238.95 6.81124 242.891 20.2093C246.262 31.6717 186.015 70.0688 229.163 45.5529C234.522 42.5079 279.675 23.572 279.146 24.4332C273.078 34.3164 257.037 45.5243 246.411 50.1288C227.515 58.3168 300.266 -0.735933 300.266 19.8573C300.266 27.5385 265.035 63.8057 283.546 48.0168C288.873 43.4733 332.023 14.3734 324.378 24.4332C320.631 29.3634 289.353 61.6984 308.538 43.7929C324.901 28.5205 341.145 17.3459 316.458 42.0329C295.817 62.6736 332.279 36.5105 338.281 32.8811C368.248 14.7614 346.368 35.1142 331.241 44.4969C314.227 55.05 343.307 34.9784 348.489 32.8811C359.411 28.4603 323.535 53.2808 356.233 34.1131C371.32 25.2686 355.222 41.5945 354.649 40.449C353.901 38.9528 373.504 27.1448 375.593 33.4091C377.801 40.034 326.566 69.0354 368.201 37.809"
            stroke="#FB8686"
            strokeWidth="10"
            strokeLinecap="round"
          />
        </svg>
      ),
      desc: "We develop secure, user-friendly e-commerce websites to streamline online transactions and maximize your sales potential.",
      url: "/services/ecommerce.webp",
    },
    {
      title: "Mobile App Development",
      scratch: (
        <svg
          width="123"
          height="65"
          viewBox="0 0 123 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 10,
            left: 230,
            zIndex: -1,
          }}
        >
          <path
            d="M18.6649 22.8865C17.2329 28.6142 7.85805 33.8545 5.99329 42.246C1.6823 61.6456 40.9263 10.2844 52.4564 6.16665C62.6641 2.52112 34.1848 17.8333 25.0009 23.5904C1.48187 38.3339 33.7797 21.7604 40.6647 20.9504C55.5813 19.1954 23.3045 45.4758 15.1451 58.0858C10.7682 64.8499 50.0858 37.6293 54.7444 33.9742C116.976 -14.8538 44.8774 30.2714 46.8246 46.822C47.0729 48.9324 84.5976 28.5623 88.7117 27.1103C99.9472 23.1449 58.2305 60.5942 69.7041 57.3818C86.5499 52.6653 100.874 30.9111 115.287 21.3024C126.772 13.6462 96.5353 41.6007 88.0077 52.4538C81.2689 61.0304 100.722 49.3711 102.616 48.0539C117.874 37.4395 117.206 36.5282 107.367 54.5657"
            stroke="#FB8686"
            strokeWidth="10"
            strokeLinecap="round"
          />
        </svg>
      ),
      desc: "Our team creates feature-rich mobile applications for both iOS and Android, delivering seamless experiences across devices.",
      url: "/services/mobapp.webp",
    },
    {
      title: "Responsive Design",
      scratch: (
        <svg
          width="341"
          height="58"
          viewBox="0 0 341 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 15,
            left: 0,
            zIndex: -1,
          }}
        >
          <path
            d="M18.2306 24.5481C13.4888 25.1409 7.93748 35.8781 5.55891 39.6838C2.10723 45.2066 33.3764 5.18848 34.4223 5.18848C44.9955 5.18848 24.3583 23.8171 20.1667 33.5239C16.2393 42.6191 13.436 51.136 25.2706 42.8518C35.5206 35.6767 85.3165 -1.9691 55.542 32.292C53.4452 34.7047 33.3067 55.7763 40.5824 52.0037C57.5753 43.1925 74.7278 34.5754 91.4454 25.2521C126.107 5.92142 84.6184 53.2575 85.8136 52.7076C94.7098 48.6154 151.41 7.45102 133.685 35.8119C122.679 53.4204 183.948 10.6852 177.684 29.476C175.973 34.6078 157.031 54.5865 173.812 46.1957C177.205 44.4992 195.884 29.2133 199.156 31.94C203.512 35.5702 192.479 45.5985 191.94 48.8357C189.882 61.1789 212.462 34.4193 223.443 28.4201C267.477 4.36472 208.452 51.4714 233.651 36.1639C237.411 33.8796 279.129 5.44284 280.642 12.4043C283.429 25.2234 263.923 36.9061 259.346 47.0757C255.206 56.2778 275.579 35.0756 283.458 28.772C295.579 19.0754 282.726 35.5298 280.114 38.2759C272.062 46.7404 294.653 31.59 301.938 30.18C311.053 28.4158 277.319 50.5358 288.21 46.7238C305.92 40.525 319.31 35.7732 335.025 24.5481"
            stroke="#FB8686"
            stroke-width="10"
            stroke-linecap="round"
          />
        </svg>
      ),
      desc: "Enjoy smooth, responsive websites and apps that look and function perfectly on any device, from smartphones to desktops.",
      url: "/services/webs.webp",
    },
  ];

  const ImageTextComponent = ({ title, scratch, desc, index, url }) => {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          padding: { xs: "20px", sm: "60px" },
          marginTop: { xs: "20px", sm: null },
        }}
        key={index}
      >
        {/* Left Side (Image) */}
        <Grid
          item
          xs={12}
          md={4}
          order={{ xs: 1, sm: index % 2 === 0 ? 1 : 2 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={url}
            alt="Description of image"
            sx={{
              marginTop: 12,
              width: "60%",
              height: "auto",
              borderRadius: "40px",
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          order={{ xs: 2, sm: index % 2 === 0 ? 2 : 1 }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: 0,
            }}
          >
            {isMobile ? null : scratch}
            <Typography
              sx={{
                fontWeight: "bold",
                marginBottom: "10px",
                fontSize: {
                  xs: "40px",
                  sm: "60px",
                  md: "65px",
                  lg: "65px",
                  xl: "65px",
                },
                marginTop: { xs: "20px", sm: null },

                // fontSize: "65px",
                zIndex: 0,
              }}
            >
              {title}
            </Typography>
          </Box>

          <Typography
            variant="body1"
            sx={{
              color: "#555",
              fontSize: {
                xs: "25px",
                sm: "40px",
                md: "45px",
                lg: "45px",
                xl: "45px",
              },
              // textAlign: { xs: "justify", sm: null },
            }}
          >
            {desc}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={0} style={{ width: "100%" }}>
      <Grid item xs={12}>
        <Section1 hideBtn={true} />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ marginLeft: { xs: "6vh", sm: "14vh" }, marginTop: "30px" }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "40px",
              sm: "60px",
              md: "70px",
              lg: "70px",
              xl: "70px",
            },
          }}
          fontWeight={700}
          color={"#FB8686"}
        >
          What we do
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {cardDetails.map((card, index) => (
          <ImageTextComponent
            key={index}
            scratch={card.scratch}
            index={index}
            title={card.title}
            desc={card.desc}
            url={card.url}
          />
        ))}
      </Grid>
      <WorkWithUs />
    </Grid>
  );
}

export default Services;
