import React from "react";
import stocknest from './stocknestsData.json'; // Import the JSON data
import Products from "./Products";

const Stocknest = () => {
  return (
    <Products data={stocknest} />
  );
};

export default Stocknest;
