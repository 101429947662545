import { HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import NavBar from "./Views/Components/NavBar";
import HomePage from "./Views/HomePage/HomePageNew";

import Footer from "./Views/Footer/FooterNew";
import Refease from "./Views/Products/Refease.jsx";
import Stocknest from "./Views/Products/Stocknest";
import Namnews from "./Views/Products/Namnews";
import ContactUsNew from "./Views/ContactUs/ContactUsNew";

import AboutUs from "./Views/AboutsUs/AboutUs";
import Services from "./Views/Services/Services";
import "./App.css";

function App() {
  return (
    <HelmetProvider>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUsNew />} />
        <Route path="/products/refease" element={<Refease />} />
        <Route path="/products/stocknest" element={<Stocknest />} />
        <Route path="/products/namnews" element={<Namnews />} />
        <Route path="/services" element={<Services />} />
      </Routes>
      <Footer />
    </HelmetProvider>
  );
}

export default App;
