import { Box, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Facebook, Instagram } from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router-dom";

function FooterNew() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  console.log(isMobile);
  const products = [
    { name: "RefEase", url: "/products/refease" },
    { name: "StockNest", url: "/products/stocknest" },
    { name: "NamNews", url: "/products/namnews" },
  ];
  const services = [
    { name: "Website Building", url: "/services" },
    { name: "Mobile Application Development", url: "/services" },
    { name: "End-to-end Product Development", url: "/services" },
  ];

  return (
    <Grid
      container
      style={{
        padding: "16px",
        backgroundColor: "#000000",
      }}
      spacing={isMobile ? 4 : 0} // Adds spacing for mobile view
    >
      <Grid
        item
        xs={12}
        style={{
          margin: "20px",
          paddingLeft: isMobile ? "20px" : 0,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-around",
        }}
        alignItems={"flex-start"}
        gap={isMobile ? "20px" : "0"}
      >
        {/* Logo and Contact */}
        <Grid item xs={12} sm={3} textAlign={"left"}>
          <Typography gutterBottom>
            <img
              src="/assets/logo.png"
              alt="Bytecrafts Logo"
              style={{ height: "80px" }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#FB8686",
              fontWeight: 500,
              fontSize: "32px",
              marginTop: "15px",
            }}
          >
            Get in touch
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: "8px" }}>
            <MailIcon sx={{ color: "#FFFFFF", mr: "8px" }} />
            <a
              href="mailto:bytecrafts24@gmail.com"
              style={{ textDecoration: "none" }}
            >
              <Typography
                sx={{ color: "#FFFFFF", fontWeight: 500, fontSize: "26px" }}
              >
                bytecrafts24@gmail.com
              </Typography>
            </a>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PhoneIcon sx={{ color: "#FFFFFF", mr: "8px" }} />
            <a href="tel:+916362718708" style={{ textDecoration: "none" }}>
              <Typography
                sx={{ color: "#FFFFFF", fontWeight: 500, fontSize: "28px" }}
              >
                +91-6362718708
              </Typography>
            </a>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
            <Link
              href="https://www.facebook.com/profile.php?id=61555965698271"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              marginRight="10px"
              data-testid="facebook-icon" // Attach test id to the <Link> itself
            >
              <Facebook style={{ color: "grey", fontSize: "40px" }} />
            </Link>
            <Link
              href="https://www.instagram.com/bytecrafts24/"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              marginRight="10px"
              data-testid="instagram-icon"
            >
              <Instagram style={{ color: "grey", fontSize: "40px" }} />
            </Link>
            <Link
              href="https://www.linkedin.com/in/byte-craft-4b62532b2/"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              marginRight="10px"
              data-testid="linkedin-icon"
            >
              <LinkedInIcon style={{ color: "grey", fontSize: "40px" }} />
            </Link>
            <Link
              href="https://www.youtube.com/@bytecrafts-24"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              marginRight="10px"
              
              data-testid="youtube-icon"
            >
              <YouTubeIcon style={{ color: "grey", fontSize: "40px" }} />
            </Link>
          </Box>
        </Grid>

        {/* Products */}
        <Grid item xs={12} sm={3} textAlign={"left"}>
          <Typography
            sx={{
              color: "#FB8686",
              fontWeight: 500,
              fontSize: "32px",
              marginTop: "15px",
            }}
          >
            Products
          </Typography>
          {products.map((product) => (
            <Typography
              key={product.name}
              sx={{
                color: "#FFFFFF",
                fontWeight: 500,
                fontSize: "26px",
                cursor: "pointer",
                marginTop: "20px",
              }}
              onClick={() => navigate(product.url)}
            >
              {product.name}
            </Typography>
          ))}
        </Grid>

        {/* Services */}
        <Grid item xs={12} sm={3} textAlign={"left"}>
          <Typography
            sx={{
              color: "#FB8686",
              fontWeight: 500,
              fontSize: "32px",
              marginTop: "15px",
            }}
          >
            Services
          </Typography>
          {services.map((service) => (
            <Typography
              key={service.name}
              sx={{
                color: "#FFFFFF",
                fontWeight: 500,
                fontSize: "26px",
                cursor: "pointer",
                marginTop: "20px",
              }}
              onClick={() => navigate(service.url)}
            >
              {service.name}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FooterNew;
